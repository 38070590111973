import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import {StatisticsWidget5} from "@metronic/_metronic/partials/widgets/index"
import {Link, usePage} from "@inertiajs/react"
import {KTIcon} from "@metronic/_metronic/helpers/index"

const AccountBalanceCards = () => {
  const {props: pageProps} = usePage()
  const [accountBalance, setAccountBalance] = useState(0)
  const [availableBalance, setAvailableBalance] = useState(0)
  const [lockedBalance, setLockedBalance] = useState(0)

  useEffect(() => {
    const invest = Number(pageProps?.account_balance?.invest) || 0

    setAccountBalance(Number(pageProps?.account_balance?.total) || 0)
    setAvailableBalance(Number(pageProps?.account_balance?.available) || 0)
    setLockedBalance((Number(pageProps?.account_balance?.locked) + invest) || 0)
  }, [pageProps])

  return (
    <Row className='g-5 g-xl-10 mb-5 mb-xl-10'>
      <Col className="col-12 col-sm-6 col-xxl-3">
        <StatisticsWidget5
          className="mb-md-0 text-center cursor-default"
          svgIcon='chart-simple'
          iconColor='primary'
          title='Account Balance'
          description={`${Intl.NumberFormat().format(accountBalance)} USD`}
          descriptionColor='muted'
          descriptionFontSize='3'
          descriptionFontWeight='bold'
        />
      </Col>
      <Col className="col-12 col-sm-6 col-xxl-3">
        <StatisticsWidget5
          className="mb-md-0 text-center cursor-default"
          svgIcon='chart-simple-3'
          iconColor='danger'
          title='Locked Balance'
          description={`${Intl.NumberFormat().format(lockedBalance)} USD`}
          descriptionColor='muted'
          descriptionFontSize='3'
          descriptionFontWeight='bold'
        />
      </Col>
      <Col className="col-12 col-sm-6 col-xxl-3">
        <StatisticsWidget5
          className="mb-md-0 text-center cursor-default"
          svgIcon='chart-simple-2'
          iconColor='success'
          title='Available Balance'
          description={`${Intl.NumberFormat().format(availableBalance)} USD`}
          descriptionColor='muted'
          descriptionFontSize='3'
          descriptionFontWeight='bold'
        />
      </Col>
      <Col className="col-12 col-sm-6 col-xxl-3">
        <Link href="/wallet" className="card hoverabletext-center h-100">
          <div className='card-body text-center px-1 px-sm-3 py-1 py-sm-5 h-100'>
            <KTIcon iconName='chart-pie-simple' className='text-primary pb-1 fs-3x ms-n1'/>
            <div className='fw-bold fs-1 mb-2 mt-4'>
              My Wallet
              <i className="bi bi-arrow-up-right-square-fill fs-2x opacity-75 text-primary ms-3"></i>
            </div>
          </div>
        </Link>
      </Col>
    </Row>
  )
}

export default AccountBalanceCards
