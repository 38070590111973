import React, {useEffect, useState} from 'react'
// import {clsx} from "clsx"
import {detailsDisclaimerText} from "@metronic/Components/Securities/Components.jsx"
import _ from "lodash";
// import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"

// const keysList = [
//   'Exchange',
//   'Sector',
//   'Industry',
//   'AnalystTargetPrice',
//   '50DayMovingAverage',
//   '52WeekHigh',
//   '52WeekLow',
//   'PERatio',
//   'ForwardPE',
//   'EPS',
//   'DividendPerShare',
//   'ExDividendDate',
//   'DividendDate',
//   'DividendYield'
// ]

const AboutTab = (props) => {
  const {
    selectedSecurities,
    activeTableTab,
    consultEmail
  } = props
  const [isReadMore, setIsReadMore] = useState(false)
  const [description, setDescription] = useState(null)
  const [overviewData, setOverviewData] = useState(null)
  const [keyDataList, setKeyDataList] = useState([])
  const [securityTickerData, setSecurityTickerData] = useState([])

  useEffect(() => {
    if (!_.isEmpty(selectedSecurities) && selectedSecurities.api_data) {
      setSecurityTickerData(selectedSecurities.api_data)
    } else {
      setSecurityTickerData([])
    }
  }, [selectedSecurities])

  useEffect(() => {
    if (securityTickerData && securityTickerData?.stock_overview_data) {
      setOverviewData(securityTickerData.stock_overview_data.data)
    } else {
      setOverviewData([])
    }
  }, [securityTickerData, selectedSecurities, activeTableTab])

  useEffect(() => {
    if (overviewData && overviewData['Description']) {
      setDescription(overviewData['Description'])
    } else if (selectedSecurities && selectedSecurities.meta && selectedSecurities.meta['about']?.value) {
      setDescription(selectedSecurities.meta['about']?.value)
    } else {
      setDescription('')
    }
  }, [activeTableTab, overviewData, selectedSecurities])

  // useEffect(() => {
  //   if (overviewData && selectedSecurities?.type !== SecurityType.BOND) {
  //     let data = []
  //
  //     for (const key in overviewData) {
  //       if (keysList.includes(key)) {
  //         data = [...data,
  //           {
  //             name: key,
  //             value: overviewData[key]
  //           }
  //         ]
  //       }
  //     }
  //
  //     setKeyDataList(data)
  //   } else {
  //     setKeyDataList(null)
  //   }
  // }, [overviewData, selectedSecurities])

  useEffect(() => {
    if (isReadMore) {
      setIsReadMore(false)
    }
  }, [activeTableTab])

  return (
    <div>
      <div className="d-flex flex-column">
        <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">About
          <span className="text-gray-800"> {selectedSecurities?.name}</span>
          {/*{(overviewData && overviewData['Symbol'] && selectedSecurities?.type !== SecurityType.BOND) ?*/}
          {/*  <span className="text-gray-800"> ({overviewData['Symbol']})</span> : null*/}
          {/*}*/}
        </h4>
        <div className="rounded-4 mt-3 text-gray-700 bg-light-info p-3">
          {description ?
            // description.length > 150 ?
            //   <>
            //     <span className={clsx('', !isReadMore && 'd-inline')}>
            //       {(description && description.length > 150) ? description.slice(0, 150) : ''}
            //     </span>
            //     <span className={clsx('', isReadMore && 'd-none')}>...</span>
            //     <span className={clsx('', !isReadMore && 'd-none')}>{description}</span>
            //     <span
            //       className="fw-bold text-gray-900 fw-bold cursor-pointer p-1"
            //       onClick={() => setIsReadMore(!isReadMore)}
            //     >
            //       {!isReadMore ? 'Show more' : 'Show less'}
            //     </span>
            //   </>
             <span>{description}</span> :
              <h5 className="w-100 mt-1 text-center text-gray-600">No available information</h5>
            }
            {(overviewData && overviewData['Official Site']) ?
            <div className="w-100 text-start mt-5 fw-bold">
              Official site: <a href={`${overviewData['Official Site']}`} target='_blank'>
              {overviewData['Official Site']}</a>
            </div> : null
          }
        </div>
      </div>

      {/*{keyDataList && keyDataList.length ?*/}
      {/*  <div className="d-flex flex-column mt-5">*/}
      {/*  <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">*/}
      {/*  Key Data*/}
      {/*    {(overviewData && overviewData['Symbol']) ?*/}
      {/*      <span className="text-gray-800"> ({overviewData['Symbol']})</span> : null*/}
      {/*    }*/}
      {/*  </h4>*/}

      {/*  <div className="table-responsive">*/}
      {/*    <table className="table table-striped gs-7">*/}
      {/*      <tbody>*/}
      {/*      {keyDataList.map((item, idx) => (*/}
      {/*        <tr key={idx}>*/}
      {/*          <td className="text-nowrap fw-bold">{item.name}:</td>*/}
      {/*          <td className="text-nowrap text-start fw-bold">{item.value}</td>*/}
      {/*        </tr>*/}
      {/*      ))}*/}
      {/*      </tbody>*/}
      {/*    </table>*/}
      {/*  </div>*/}

      {/*</div> : null*/}
      {/*}*/}

      {detailsDisclaimerText(consultEmail)}
    </div>
    )
  }

  export default AboutTab
