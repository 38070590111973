import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import clsx from "clsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {InvestmentsDetailsTable, Last5Transactions} from "@metronic/Components/Tables"
import {MyWalletDetailsWidget} from "@metronic/Components/CustomWidgets"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {recordSecuritiesApiData} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {usePage} from "@inertiajs/react"
import _ from "lodash"
import {selectListData} from "@metronic/api/general.js"

const InvestmentsContent = () => {
  const {props: pageProps} = usePage()
  const acquiredList = pageProps?.acquired_securities
  const [securitiesList, setSecuritiesList] = useState([])
  const [isSecuritiesListReady, setIsSecuritiesListReady] = useState(false)
  const [assetsList, setAssetsList] = useState([])
  const [securitiesApiData, setSecuritiesApiData] = useState([])

  useEffect(() => {
    if (securitiesList?.length && !_.isEmpty(assetsList) && !_.isEmpty(securitiesApiData)) {
      recordSecuritiesApiData(securitiesList, setSecuritiesList, assetsList, securitiesApiData, setIsSecuritiesListReady)
    }
  }, [assetsList, securitiesApiData])

  useEffect(() => {
    if (!_.isEmpty(pageProps?.securities_data)) {
      setSecuritiesApiData(pageProps?.securities_data || [])
    }
  }, [pageProps])

  useEffect(() => {
    selectListData('assets_list').then(r => {
      setAssetsList(r)
    })
  }, [])

  useEffect(() => {
    if (!_.isEmpty(acquiredList)) {
      setSecuritiesList(acquiredList.map(i => i.security))
    }
  }, [acquiredList])

  return (
    <>
      <Toolbar isWallet={true} activeTab={'investments'} />
      <Content>
        <Row className="mb-3">
          <Col className={clsx(VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-9' : 'col-12 col-xxl-9')}>
            <InvestmentsDetailsTable currentList={securitiesList} isSecuritiesListReady={isSecuritiesListReady}/>
          </Col>

          <Col className={clsx(VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-3' : 'col-12 col-xxl-3')}>
            <MyWalletDetailsWidget
              isInvestmentDetails={true}
              currentList={securitiesList}
              assetsList={assetsList}
            />
          </Col>
        </Row>

        <Row className="pt-5 pb-5">
          <Col className="col-12">
            <Last5Transactions investments={true}/>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default InvestmentsContent
