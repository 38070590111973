import React, {useEffect, useState} from 'react'
import docImg from "../../../../../../public/media/svg/files/doc.svg"
import {blockCardTitle} from "@metronic/Components/Securities/Components.jsx"
import {Card, CardBody} from "reactstrap"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"

const DocumentsAndInformation = ({blockId}) => {
  const {tabData} = useSecuritiesContext()
  const [blockData, setBlockData] = useState({})
  const [documentsList, setDocumentsList] = useState([])

  const openDocumentHandler = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      setBlockData(tabData.find(i => i.uuid === blockId))
    }
  }, [tabData, blockId])

  useEffect(() => {
    if (blockData?.documents_list) {
      setDocumentsList(blockData?.documents_list.filter(i => i.link && i.title))
    }
  }, [blockData])

  return (
    <Card>
      <CardBody>
        {blockCardTitle(blockData.title)}

        {(blockData && documentsList?.length) ?
          <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
            <div className="mb-5">
              <div
                className="accordion-header py-3 d-flex"
                data-bs-toggle="collapse"
                data-bs-target={`#kt_accordion_${blockId}`}
              >
                <h3 className="fs-4 text-gray-800 fw-bold mb-0 me-4">
                  Documents list
                </h3>
                <span className="accordion-icon">
              <i className="bi bi-chevron-double-right"></i>
            </span>
              </div>
              <div
                id={`kt_accordion_${blockId}`}
                className="fs-6 collapse show ps-10"
                data-bs-parent="#kt_accordion_2"
              >
                <div className="d-flex pt-5 flex-column overflow-scroll">
                  {(blockData && documentsList.length) ? documentsList.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="text-gray-800 fs-4 fw-bold d-flex align-items-center"
                      >
                        <div
                          className='symbol symbol-30px me-3 mb-2 cursor-pointer'
                          onClick={() => openDocumentHandler(item.link)}
                        >
                          <img src={docImg} alt='#'/>
                        </div>
                        <div className="cursor-pointer" onClick={() => openDocumentHandler(item.link)}>{item.title}</div>
                      </div>
                    )
                  }) : null}
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {(blockData && blockData.information?.length) ?
          <>
            <h3 className="fs-4 text-gray-800 fw-bold mb-0 my-5 py-5">
              Information
            </h3>
            <div className="bg-light p-10 rounded-3" style={{textAlign: 'justify'}}>
              {blockData.information.length &&
                <span className="fs-6 me-2 pb-3">
                  {
                    blockData.information.split(/\r?\n/)
                      .map((string, idx) => (
                        <React.Fragment key={idx}>
                          <span>{string}</span> <br/>
                        </React.Fragment>
                      ))
                  }
                </span>
              }
            </div>
          </> : null
        }
      </CardBody>
    </Card>
  )
}

export default DocumentsAndInformation
