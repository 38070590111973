import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row} from "reactstrap"
import {clsx} from "clsx"
import _, {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"
import {blockCardTitle} from "@metronic/Components/Securities/Components.jsx"
import {SecurityDetail} from "@metronic/Components/Securities/Blocks/index.jsx"
import {checkImageValidity, formatSecuritiesPrice, useWindowSize} from "@metronic/helpers/utils.jsx"
import {getAcquiredSecurities} from "@metronic/api/securities.js"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"

const OwnSecuritiesTable = ({blockId}) => {
  const {
    tabData,
    ownSelectedSecurities,
    setOwnSelectedSecurities,
    securitiesList
  } = useSecuritiesContext()
  const [blockData, setBlockData] = useState({})
  const [isMouseOnRow, setIsMouseOnRow] = useState(0)
  const [imageValidity, setImageValidity] = useState({})
  const [ownSecuritiesList, setOwnSecuritiesList] = useState([])
  const [acquiredSecurities, setAcquiredSecurities] = useState([])
  const [consultEmail, setConsultEmail] = useState('')
  const [selectedSaap, setSelectedSaap] = useState({})
  const [isAcquiredListLoading, setIsAcquiredListLoading] = useState(false)
  const {width: windowWidth} = useWindowSize()

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      setBlockData(tabData.find(block => block.uuid === blockId))
    }
  }, [tabData, blockId])

  const getAcquiredSecuritiesList = () => {
    setIsAcquiredListLoading(true)
    getAcquiredSecurities().then(r => {
      const updatedList = r.map(item => {
        let securityFromList = securitiesList.find(i => i.id === item.security_id)

        if (!securityFromList) return

        if (item.security?.type !== SecurityType.BOND && securityFromList) {
          if (!item.security?.price) {
            item.security.price = securityFromList.fields.current_price.value
          }

          // if (!item.security?.meta?.volume?.value) {
          //   item.security.meta.volume.value = securityFromList.meta.volume.value
          // }

          if (!item.security?.fields?.payout_asset?.value) {
            item.security.fields.payout_asset = securityFromList.fields.payout_asset
          }
        }

        if (securityFromList) {
          item.security['api_data'] = securityFromList.api_data
        }

        return item
      })
        .filter(i => !_.isEmpty(i))

      setAcquiredSecurities(!_.isEmpty(updatedList) ? updatedList : [])
    }).finally(() => {
      setIsAcquiredListLoading(false)
    })
  }

  useEffect(() => {
    getAcquiredSecuritiesList()
  }, [])

  useEffect(() => {
    if (acquiredSecurities && acquiredSecurities.length) {
      setOwnSecuritiesList(acquiredSecurities.flatMap(i => i.security))
    }
  }, [acquiredSecurities])

  useEffect(() => {
  }, [ownSecuritiesList, acquiredSecurities])

  const resetSelectedSecurities = () => {
    setOwnSelectedSecurities({})
  }

  useEffect(() => {
    const validateImages = async () => {
      const results = {}
      for (const item of ownSecuritiesList) {
        const fields = item.fields
        if (fields['logo'].value) {
          const isValid = await checkImageValidity(fields['logo'].value)
          results[item.id] = isValid
        } else {
          results[item.id] = false
        }
      }
      setImageValidity(results)
    }

    validateImages()
  }, [ownSecuritiesList])

  useEffect(() => {
    if (ownSecuritiesList) {
      if (Number(windowWidth >= 576) && !ownSelectedSecurities?.name) {
        setOwnSelectedSecurities(ownSecuritiesList[0])
      }
    }
  }, [ownSecuritiesList, windowWidth])

  useEffect(() => {
    const fields = ownSelectedSecurities?.fields

    setConsultEmail(fields?.consult_email?.value || '')

    const payoutAssetCode = fields?.payout_asset?.payout_asset_code || 'USD'
    const currentPrice = securitiesList.find(i => i.ticker === fields?.ticker?.value)?.price || 0.00

    setSelectedSaap({
      code: payoutAssetCode,
      price: currentPrice,
    })
  }, [ownSelectedSecurities])

  return (
    <>
      <Row className="g-5 g-xl-10 mb-5 mb-xl-10">

        {(!ownSelectedSecurities?.name || windowWidth >= 576) ?
          <Col className={clsx('col-12', !ownSecuritiesList.length ? '' : (
            VITE_APP_VARIANT === 'light' ? 'col-md-5 col-lg-12 col-xl-5 col-xxl-4' : 'col-md-5 col-lg-4'
          ))}>
            <Card className="pb-3">
              <span className="pt-7 px-9">{blockCardTitle(blockData.title)}</span>
              <CardBody className={clsx('overflow-y-scroll pt-0',
                !ownSelectedSecurities?.name ? '' : (
                  VITE_APP_VARIANT === 'light' ? 'mh-sm-300px mh-md-100 mh-lg-350px mh-xl-100' : 'mh-sm-300px mh-md-100'
                )
              )}>

                {!ownSecuritiesList.length ?
                <h2 className="text-center fw-bold text-gray-600">
                  {isAcquiredListLoading ? 'Loading data...' : 'There are no records to display'}
                </h2> : null
                }

                <div>
                  {(ownSecuritiesList && ownSecuritiesList.length) ?
                    ownSecuritiesList.map((item, idx) => {
                      const id = idx + 1
                      const meta = item.fields
                      const isValid = imageValidity[item.id]

                      return (
                        <div
                          className={clsx('d-flex align-items-center mb-2 rounded-3 cursor-pointer',
                            isMouseOnRow === id && 'bg-light-success',
                            (ownSelectedSecurities && Number(ownSelectedSecurities.id) === Number(item.id)) && 'bg-light-success'
                          )}
                          key={idx}
                          onClick={() => setOwnSelectedSecurities(
                            ownSecuritiesList[idx]
                          )}
                          onMouseEnter={() => setIsMouseOnRow(id)}
                          onMouseLeave={() => setIsMouseOnRow(0)}
                        >

                          {(meta['logo']?.value && isValid !== undefined) ?
                            (
                              isValid ? (
                                  <div className="symbol symbol-40px border p-1 ms-2 me-5">
                                    <img src={meta['logo'].value} alt="image"/>
                                  </div>
                                ) :
                                <div className="symbol symbol-40px border p-1 ms-2 me-5">
                                  <div className="symbol-label fs-2 fw-semibold text-primary">
                                    {item.name.slice(0, 1)}
                                  </div>
                                </div>
                            ) :
                            <div className="symbol symbol-40px border p-1 ms-2 me-5">
                              <div className="symbol-label fs-2 fw-semibold text-primary">
                                {item.name.slice(0, 1)}
                              </div>
                            </div>
                          }

                          <div className='d-flex justify-content-between text-truncate align-items-center w-100'>
                            <div className="d-flex flex-column text-nowrap text-truncate py-1">
                              <span className="fs-7 text-gray-800 fw-bolder text-hover-primary">{item.name}</span>
                              <span className="fs-9 text-success fw-bolder">{capitalize(item.type)}</span>
                              <span className="fs-9 fw-semibold text-gray-500">
                            Current price: {formatSecuritiesPrice(
                              meta['payout_asset']?.payout_asset_code, securitiesList.find(i => i.ticker === meta?.ticker?.value)?.price || 0.00
                              )}
                          </span>
                            </div>
                            <div style={{
                              opacity: (isMouseOnRow === id || Number(ownSelectedSecurities?.id) === Number(item.id)) ? 1 : 0,
                              transition: 'opacity ease-in-out 0.3s'
                            }}
                            >
                              <KTIcon iconName="double-right" className="fs-1 cursor-pointer me-3"/>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : null
                  }
                </div>

              </CardBody>
            </Card>
          </Col>
          : null
        }

        {ownSelectedSecurities?.name ?
          <Col className={clsx('',
            VITE_APP_VARIANT === 'light' ? 'col-md-7 col-lg-12 col-xl-7 col-xxl-8' : 'col-12 col-md-7 col-lg-8'
          )}>
            <Card>
              <CardBody>
                <SecurityDetail
                  selectedSecurities={ownSelectedSecurities}
                  resetSelectedSecurities={resetSelectedSecurities}
                  isOwnDetails={true}
                  acquiredSecurity={acquiredSecurities.find(i => i.security_id === ownSelectedSecurities.id) || null}
                  consultEmail={consultEmail}
                  selectedSaap={selectedSaap}
                  updateDetailsTable={getAcquiredSecuritiesList}
                />
              </CardBody>
            </Card>
          </Col> : null
        }
      </Row>
    </>
  )
}

export default OwnSecuritiesTable
