import React, {useContext, useEffect, useState} from 'react'
import {usePage} from "@inertiajs/react";
import {useTranslation} from "react-i18next"
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  toTwoPrecision,
  TransactionBadgeIcon,
  TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"
import {TransactionsModalContext} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"
import {capitalize} from "lodash"
import {Tooltip} from "react-tooltip"

const Last5Transactions = ({investments}) => {
  const {props: pageProps} = usePage()
  const {t} = useTranslation()
  const [data, setData] = useState([])
  const {openTransferDetailsModal} = useContext(TransactionsModalContext)
  const [isUrlsValid, setIsUrlsValid] = useState([])

  useEffect(() => {
    if (pageProps?.last_transactions) {
      setData(pageProps?.last_transactions)
    }
  }, [pageProps])

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label text-gray-700 fw-bold fs-3 mb-1'>Last 5 transactions</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
              <tr className='fw-bold fs-5 text-gray-700 bg-light'>
                <th className='ps-4 min-w-100px rounded-start fw-bold fs-5'>ID</th>
                <th className='min-w-100px fw-bold fs-5'>Type</th>
                <th className='min-w-150px fw-bold fs-5'>Created date</th>
                <th className={`min-w-${investments ? '100' : '150'}px fw-bold fs-5`}>Base Asset</th>
                <th className='min-w-150px fw-bold fs-5'>Quote Asset</th>
                <th className='min-w-100px fw-bold fs-5'>Amount</th>
                <th className={`min-w-${investments ? '100' : '150'}px fw-bold fs-5`}>USD Amount</th>
                {investments ? <th className='fw-bold fs-5'>Quantity</th> : null}
                <th className='min-w-100px fw-bold fs-5'>Fee Pct</th>
                <th className='min-w-100px rounded-end fw-bold fs-5'>Status</th>
              </tr>
              </thead>

              <tbody>
              {data ? (data?.length ?
                data.map((item, index) => {
                  let securityLogoUrl = ''

                  if (investments && item?.security?.meta?.fields?.logo?.value) {
                    securityLogoUrl = item?.security?.meta?.fields?.logo?.value
                  }

                  return (
                    <tr key={index} className="align-middle">
                      <td className="fw-bold text-primary py-3">
                        <a className="cursor-pointer"
                           onClick={() => openTransferDetailsModal(item)}>#{item.id}</a>
                      </td>
                      <td className="py-3">
                        {investments ?
                          <span className="fw-bold">
                            {capitalize(item?.security?.meta?.type)}
                          </span>
                          :
                          <TransactionBadgeIcon
                            transactionId={item.id}
                            transactionType={item.type}
                            invoiceId={item.invoice_id}
                            invoiceAmount={item.usd_amount}
                          />
                        }
                      </td>
                      <td className="fw-bold py-3">{prettyDate(item.created_at)}</td>
                      <td className="py-1">
                        <div className="d-flex align-items-center">
                          <div className="h-25px w-25px rounded-circle me-2">
                            <img
                              src={cryptoIconsMapper[item.base_asset.currency.code]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          <span className="fw-bold">{item.base_asset.currency.code}</span>
                        </div>
                      </td>
                      <td className="fw-bold py-3">
                        {investments ?
                          <div className="d-flex align-items-center mw-150px">
                            <div className='symbol symbol-25px symbol-circle me-2'>
                              {(securityLogoUrl && !isUrlsValid.includes(String(index))) ?
                                <img
                                  src={securityLogoUrl}
                                  className='align-self-end border'
                                  alt='image'
                                  onError={() => setIsUrlsValid(prev => ([...prev, String(index)]))}
                                /> :
                                <div className="symbol-label border fs-2 fw-semibold text-primary">
                                  {capitalize(item?.security?.meta?.name.slice(0, 1))}
                                </div>
                              }
                            </div>
                            <div
                              data-tooltip-id={`${index}`}
                              data-tooltip-content={capitalize(item?.security?.meta?.name)}
                              className="cursor-default text-truncate"
                            >
                              {capitalize(item?.security?.meta?.name)}
                            </div>
                            <Tooltip id={`${index}`} />
                          </div>
                          :
                          (item.quote_asset?.currency ?
                            <div className="d-flex align-items-center">
                              <div className="h-25px w-25px rounded-circle me-2">
                                <img
                                  src={cryptoIconsMapper[item.quote_asset.currency.code]}
                                  className="img-fluid"
                                  alt="3"
                                />
                              </div>
                              {item.quote_asset.currency.code}
                            </div> : '-'
                          )
                        }
                      </td>
                      <td className="fw-bold py-3">{isTwoPrecision(item.base_asset.currency.code, item.base_amount)}</td>
                      <td className="fw-bold py-3">${toTwoPrecision(item.usd_amount)}</td>
                      {investments ?
                        <td className="fw-bold py-3">
                          {item?.meta?.quantity}
                        </td> : null
                      }
                      <td className="fw-bold py-3">{Number(item.fee_pct)}%</td>
                      <td className="py-1">
                        <TransactionStatusBadge status={item.status} color={item.status_color} />
                      </td>
                    </tr>
                  )
                }) : (<tr>
                  <td colSpan={11} className='text-center fs-5 pt-5'>{t("There are no records to display")}</td>
                </tr>)
                ) : (<tr>
                  <td colSpan={11} className='text-center fs-5 pt-5'>{t("Loading")}...</td>
                </tr>)
              }
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Last5Transactions
